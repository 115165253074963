/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {

        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages





                // Navigation
                var navMenu     = $('.main__nav');
                var navOpen     = $('.nav__open');
                var navClose    = $('.nav__close');

                // $('body').focusin(function(e) {
                //     if (!$.contains($(navMenu)[0], document.activeElement)) {
                //         navClose.trigger('click');
                //     }
                // });

                //keyboard navigation
                // navMenu.on('keydown', function(event){
                //     // keyCode -> http://www.javascripter.net/faq/keycodes.htm
                //     // 27 = esc
                //     if  (event.keyCode === 27) {
                //         navClose.trigger('click');
                //     }
                // });

                // open navigation
                navOpen.click(
                    function(){
                        navOpen.attr('aria-expanded', navOpen.attr( 'aria-expanded' ) === 'false' ? 'true' : 'false' );
                        navClose.attr('aria-expanded', navClose.attr( 'aria-expanded' ) === 'false' ? 'true' : 'false' );
                        navMenu.addClass('open');
                        // $('html, body').addClass('main__nav-opened');
                    }
                );

                // close navigation
                navClose.click(
                    function(){
                        navOpen.attr('aria-expanded', navOpen.attr( 'aria-expanded' ) === 'false' ? 'true' : 'false' );
                        navClose.attr('aria-expanded', navClose.attr( 'aria-expanded' ) === 'false' ? 'true' : 'false' );
                        navMenu.removeClass('open');
                        // $('html, body').removeClass('main__nav-opened');
                    }
                );

                // menuProducts
                var menuMainProducts = $('.main_menu li.products a');
                var menuProducts = $('.menu__products');
                var menuProductsClose = $('.menu__products__close');

                menuMainProducts.click(
                    function(){
                        menuMainProducts.attr('aria-expanded', menuMainProducts.attr( 'aria-expanded' ) === 'false' ? 'true' : 'false' );
                        menuProducts.addClass('open');
                    }
                );
                menuProductsClose.click(
                    function(){
                        menuMainProducts.attr('aria-expanded', menuMainProducts.attr( 'aria-expanded' ) === 'false' ? 'true' : 'false' );
                        menuProducts.removeClass('open');
                    }
                );


                // var menuItems = document.querySelectorAll('.main_menu li.menu-item-has-children');
                // var menuItems = document.querySelectorAll('.main_menu li.products');
                // var menuItemOpenClose = function(event){
                //     if ( this.parentNode.classList.contains('open') ) {
                //         this.parentNode.classList.remove('open');
                //         this.setAttribute('aria-expanded', "false");
                //     } else {
                //         menuItems.forEach( function(e) {
                //             e.classList.remove('open');
                //             e.setAttribute('aria-expanded', "true");
                //         });
                //         this.parentNode.classList.add('open');
                //         this.setAttribute('aria-expanded', "true");
                //     }
                //     event.preventDefault();
                //     //event.stopPropagation();
                //     return false;
                // }
                // Array.prototype.forEach.call(menuItems, function(el, i){
                // 	el.querySelector('a').addEventListener("click", menuItemOpenClose, false);
                // });





                // Header after scroll
                var headerScrollAnchor = $('#main').first().offset().top + 80;
                var header = $('#header');

                var headerFixed = function(){
                    var wst = $(window).scrollTop();
                    if( wst >= headerScrollAnchor ){
                        header.addClass('after-scroll');
                    } else {
                        header.removeClass('after-scroll');
                    }
                };
                headerFixed();

                $(window).on('scroll', function() {
                    headerFixed();
                });





                // AOS - animate on scroll
                // setting
                AOS.init({
                    offset: 100,
                    once: true,
                });





                // Magnific Popup
                // Image
                var magPopImg = $('.magnificpopup');
                magPopImg.magnificPopup({
                    type: 'image',
                    image: {
                        titleSrc:
                        function(item) {
                            return item.el.find('img').attr('alt');
                        }
                    }
                });

                // Video
                var magPopVideo = $('.magnificpopup--video');
                magPopVideo.magnificPopup({
                    type: 'iframe',
                    iframe: {
                        markup:
                            '<div class="mfp-iframe-scaler">'+
                                '<div class="mfp-close"></div>'+
                                '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>'+
                            '</div>', // HTML markup of popup, `mfp-close` will be replaced by the close button
                        patterns: {
                            youtube: {
                                index: 'youtube.com/', // String that detects type of video (in this case YouTube). Simply via url.indexOf(index).

                                id: 'v=', // String that splits URL in a two parts, second part should be %id%
                                // Or null - full URL will be returned
                                // Or a function that should return %id%, for example:
                                // id: function(url) { return 'parsed id'; }

                                src: '//www.youtube.com/embed/%id%' // URL that will be set as a source for iframe.
                            },
                        },
                    }
                });

                // Gallery
                var magPopGallery = $('.magnificpopup--gallery');
                magPopGallery.each(function() { // the containers for all your galleries

                    $(this).find('a').magnificPopup({
                        //delegate: 'a', // the selector for gallery item
                        type: 'image',
                        gallery: {
                            enabled: true
                        },
                        image: {
                            titleSrc:
                                function(item) {
                                    return item.el.find('img').attr('alt');
                                },
                        },
                    });

                });




                // // Vertical Timeline - by CodyHouse.co
                // function VerticalTimeline( element ) {
                //     this.element = element;
                //     this.blocks = this.element.getElementsByClassName("cd-timeline__block");
                //     this.images = this.element.getElementsByClassName("cd-timeline__img");
                //     this.contents = this.element.getElementsByClassName("cd-timeline__content");
                //     this.offset = 0.8;
                //     this.hideBlocks();
                // }
                //
                // VerticalTimeline.prototype.hideBlocks = function() {
                //     if ( !"classList" in document.documentElement ) {
                //         return; // no animation on older browsers
                //     }
                //     //hide timeline blocks which are outside the viewport
                //     var self = this;
                //     for( var i = 0; i < this.blocks.length; i++) {
                //         (function(i){
                //             if( self.blocks[i].getBoundingClientRect().top > window.innerHeight*self.offset ) {
                //                 self.images[i].classList.add("cd-timeline__img--hidden");
                //                 self.contents[i].classList.add("cd-timeline__content--hidden");
                //             }
                //         })(i);
                //     }
                // };
                //
                // VerticalTimeline.prototype.showBlocks = function() {
                //     if ( ! "classList" in document.documentElement ) {
                //         return;
                //     }
                //     var self = this;
                //     for( var i = 0; i < this.blocks.length; i++) {
                //         (function(i){
                //             if( self.contents[i].classList.contains("cd-timeline__content--hidden") && self.blocks[i].getBoundingClientRect().top <= window.innerHeight*self.offset ) {
                //                 // add bounce-in animation
                //                 self.images[i].classList.add("cd-timeline__img--bounce-in");
                //                 self.contents[i].classList.add("cd-timeline__content--bounce-in");
                //                 self.images[i].classList.remove("cd-timeline__img--hidden");
                //                 self.contents[i].classList.remove("cd-timeline__content--hidden");
                //             }
                //         })(i);
                //     }
                // };
                //
                // var verticalTimelines = document.getElementsByClassName("js-cd-timeline"),
                // verticalTimelinesArray = [],
                // scrolling = false;
                //
                // function checkTimelineScroll() {
                //     verticalTimelinesArray.forEach(function(timeline){
                //         timeline.showBlocks();
                //     });
                //     scrolling = false;
                // }
                //
                // if( verticalTimelines.length > 0 ) {
                //     for( var i = 0; i < verticalTimelines.length; i++) {
                //         (function(i){
                //             verticalTimelinesArray.push(new VerticalTimeline(verticalTimelines[i]));
                //         })(i);
                //     }
                //
                //     //show timeline blocks on scrolling
                //     window.addEventListener("scroll", function(event) {
                //         if( !scrolling ) {
                //             scrolling = true;
                //             (!window.requestAnimationFrame) ? setTimeout(checkTimelineScroll, 250) : window.requestAnimationFrame(checkTimelineScroll);
                //         }
                //     });
                // }










            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },



        // Home page
        'page_template_homepage': {
            init: function() {
                // JavaScript to be fired on the home page

                var value2upd = function(){
                    var w = $(window).width();
                    return w < 860 ? '0deg 75deg 125%' : '0deg 75deg 85%';
               };

               $(window).resize(function(){
                   $('.viewer').attr('camera-orbit', value2upd);
               }).resize(); // <-----------fires when dom is ready.


               anime({
                 targets: '.line-drawings path',
                 strokeDashoffset: [anime.setDashoffset, 0],
                 easing: 'easeInOutSine',
                 duration: 500,
                 delay: function(el, i) { return i * 250 },
                 direction: 'alternate',
                 loop: true
               });

            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },





        // Prodotto Figlio ( product ) -> Product Template ( product-child.php )
        'product_template_product_child': {
            init: function() {
                // JavaScript to be fired on the home page

                // init Masonry
                var $grid = $('.product-template-product-child .grid').masonry({
                    // set itemSelector so .grid-sizer is not used in layout
                    itemSelector: '.grid-item',
                    // use element for option
                    columnWidth: '.grid-sizer',
                    percentPosition: true,
                    gutter: 40,
                });

                // layout Masonry after each image loads
                $grid.imagesLoaded().progress( function() {
                    $grid.masonry('layout');
                    console.log('doh');
                });


            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },





        // Realizzazioni ( realization ) -> Single ( single-realization.php )
        'single_realization': {
            init: function() {
                // JavaScript to be fired on the home page

                var gallerySlider = $('.realization--content .gallery');

                gallerySlider.slick({
                    arrows: true,
                    autoplay: true,
                    autoplaySpeed: 4000,
                    infinite: false,
                    pauseOnHover: false,
                    dots: true,
                    variableWidth: true,
                    centerMode: true,
                    // centerPadding: '50%',
                    // slidesToShow: 1,
                    slidesToScroll: 1,
                    focusOnSelect: true,
                    rows: 0, // remove div https://github.com/kenwheeler/slick/issues/3110
                });


            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },





        'blog': {
            init: function() {
                // JavaScript to be fired on the home page

                // init Masonry
                var $grid = $('.posts--archive .grid').masonry({
                    // set itemSelector so .grid-sizer is not used in layout
                    itemSelector: '.grid-item',
                    // use element for option
                    columnWidth: '.grid-sizer',
                    percentPosition: true,
                    gutter: 40,
                });

                // layout Masonry after each image loads
                $grid.imagesLoaded().progress( function() {
                    $grid.masonry('layout');
                    console.log('doh');
                });


            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        'archive': {
            init: function() {
                // JavaScript to be fired on the home page

                // init Masonry
                var $grid = $('.posts--archive .grid').masonry({
                    // set itemSelector so .grid-sizer is not used in layout
                    itemSelector: '.grid-item',
                    // use element for option
                    columnWidth: '.grid-sizer',
                    percentPosition: true,
                    gutter: 40,
                });

                // layout Masonry after each image loads
                $grid.imagesLoaded().progress( function() {
                    $grid.masonry('layout');
                    console.log('doh');
                });


            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },





        // Post ( post ) -> Single ( single.php )
        'single_post': {
            init: function() {
                // JavaScript to be fired on the home page

                var gallerySlider = $('.layout--gallery .gallery');

                gallerySlider.slick({
                    arrows: true,
                    autoplay: true,
                    autoplaySpeed: 4000,
                    infinite: false,
                    pauseOnHover: false,
                    dots: true,
                    variableWidth: true,
                    centerMode: true,
                    // centerPadding: '50%',
                    // slidesToShow: 1,
                    slidesToScroll: 1,
                    focusOnSelect: true,
                    rows: 0, // remove div https://github.com/kenwheeler/slick/issues/3110
                });


            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },





    };// Sage

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };// UTIL

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
